export const CHANNEL_OPTIONS = [
	{ label: 'Посмотрел на сайте номер телефона' },
	{ label: 'Платформа' },
	{ label: 'Повторное обращение' },
	{ label: 'Обратный звонок с сайта' },
	{ label: 'По рекомендации' },
	{ label: 'Сайт' },
	{ label: 'Брокер' },
	{ label: 'Живосайт' },
	{ label: 'Журнал' },
	{ label: 'Прочие' },
	{ label: 'Личный кабинет клиента' },
	{ label: 'Колл-центр' },
	{ label: 'Реферальная ссылка' },
	{ label: 'Брокер. Реферальная ссылка' },
	{ label: 'Брокерский для продажи' },
	{ label: 'Эксбико' },
]

export const INVESTMENT_CHANNEL_OPTIONS = [
	{ label: 'По рекомендации' },
	{ label: 'Партнеры' },
	{ label: 'Сайт' },
	{ label: 'Личный кабинет инвестора' },
	{ label: 'Реферальная программа' },
]

export const CHANNEL_FILTER_OPTIONS = [
	'Посмотрел на сайте номер телефона',
	'Платформа',
	'Повторное обращение',
	'Обращение по обзвону',
	'Обратный звонок с сайта',
	'По рекомендации',
	'Сайт',
	'Брокер',
	'Альфа',
	'Точка',
	'Звонки по холодной базе',
	'Живосайт',
	'Журнал',
	'Прочие',
	'Личный кабинет клиента',
	'ЛК Клиента',
	'Колл-центр',
	'Реферальная ссылка',
	'Брокер. Реферальная ссылка',
	'CPA',
	'Брокерский для продажи',
	'Эксбико',
]

export const INVESTMENT_CHANNEL_FILTER_OPTIONS = [
	'По рекомендации',
	'Партнеры',
	'Сайт',
	'Личный кабинет инвестора',
	'Реферальная программа',
]

export const PURPOSE_OPTIONS = [
	{ label: 'Пополнение оборотных средств' },
	{ label: 'Покупка оборудования' },
	{ label: 'Исполнение контракта' },
	{ label: 'Покупка недвижимости' },
	{ label: 'Потребительские цели' },
	{ label: 'Развитие бизнеса' },
	{ label: 'Погашение кредитов' },
	{ label: 'Покупка автомобиля' },
	{ label: 'Ремонт' },
	{ label: 'Образование' },
	{ label: 'Лечение' },
	{ label: 'Иное' },
]

export const TRAFFIC_SOURCE = [
	{ id: 'googleorganic', label: 'googleorganic' },
	{ id: 'yandexorganic', label: 'yandexorganic' },
	{ id: 'direct', label: 'direct' },
	{ id: 'cpc', label: 'cpc' },
]

export const LEAD_STATUSES = {
	NEW: 'NEW',
	REFUSE: 'REFUSE',
	IN_PROGRESS: 'IN_PROGRESS',
	TO_APPLICATION: 'TO_APPLICATION',
}

export const LEAD_SOURCE = new Proxy(
	{
		CRM: 'Из срм',
		CPA: 'Из СPA сети',
		SITE: 'C сайта',
		BROKER: 'Из ЛК брокера',
		CLIENT: 'Из ЛК клиента',
		LENDER: 'Кредитор',
		CALL_CENTER: 'Из колл-центра',
	},
	{
		get(target: any, p) {
			if (!target[p]) return 'Неизвестно'

			return Reflect.get(target, p)
		},
	}
)

export const DOCS_TYPES = {
	PERSON: 'Заёмщик физическое лицо',
	ORGANIZATION: 'Заёмщик юридическое лицо',
	ENTREPRENEUR: 'Заёмщик индивидуальный предприниматель',
}
